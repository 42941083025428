<script setup>
import { ArrowLongLeftIcon, ArrowLongRightIcon } from '@heroicons/vue/20/solid';
import { Link } from '@inertiajs/vue3';
import { computed } from 'vue';

const props = defineProps({
    data: Object,
});

const links = computed(() => {
    return props.data.links.slice(1, -1);
});

</script>

<template>
    <nav
        v-if="data.links.length > 3"
        class="flex items-center justify-between border-t border-white px-4 sm:px-0"
    >
        <div class="-mt-px flex w-0 flex-1">
            <Link
                v-if="data.prev_page_url"
                :href="data.prev_page_url"
                class="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-bold text-blue hover:border-pink hover:text-pink"
            >
                <ArrowLongLeftIcon
                    aria-hidden="true"
                    class="mr-3 h-5 w-5 text-blue"
                />
                {{ $t('Previous') }}
            </link>
        </div>
        <div class="hidden md:-mt-px md:flex">
            <Link
                v-for="link in links"
                :class="link.label === data.current_page.toString() ? 'border-pink text-pink' : link.label === '...' ? 'border-transparent text-blue' : 'border-transparent text-blue hover:border-pink hover:text-pink' "
                :href="link.url"
                class="inline-flex items-center border-t-2 px-4 pt-4 text-sm font-bold"
            >
                {{ link.label }}
            </Link>
        </div>

        <div class="-mt-px flex w-0 flex-1 justify-end">
            <Link
                v-if="data.next_page_url"
                :href="data.next_page_url"
                class="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-bold text-blue hover:border-pink hover:text-pink"
            >
                {{ $t('Next') }}
                <ArrowLongRightIcon
                    aria-hidden="true"
                    class="ml-3 h-5 w-5 text-blue"
                />
            </Link>
        </div>
    </nav>
</template>
